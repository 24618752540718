defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/GameClients/EurojackpotSystemClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/System',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/PrintWrapper',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsComponentForGameClient',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Models/EurojackpotGame',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotDictionary',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotSystem/OptionSelector',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotSystem/Row',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotSystem/ProfitGuaranteeTab',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotSystem/InfoTab',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotSystem/KeyTab',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/PurchaseBar',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/Subnavigation',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/ShakeOverlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/NotificationBox'
  ],
  function (Params, NumberGamesSystem, Overlay, ErrorOverlay, JokerOverlay, PrintWrapper, StatisticsComponentForGameClient, EurojackpotGame, EurojackpotDictionary, EurojackpotInfo, OptionSelector, Row, ProfitGuaranteeTab, InfoTab, KeyTab, PurchaseBar, Subnavigation, ShakeOverlay, NotificationBox) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Component:
      var root = {
        controller: function () {

          this.settings = settings;
          this.property = property;

          // Variables:
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(EurojackpotGame.setupGame({
            gameInstanceId: this.gameInstanceId(),
            playType: 'System',
            verticalType: settings.verticalType,
            manualGameVersionNo: settings.manualGameVersionNo
          }));
          this.chosenTab = m.prop('game');
          this.d = EurojackpotDictionary.get;
          this.errorOverlay = m.prop(new Overlay(ErrorOverlay('eurojackpot-system-game-overlay', { header: this.d('GenerateRowsErrorOverlay/Header'), body: this.d('GenerateRowsErrorOverlay/Body'), dismiss: this.d('GenerateRowsErrorOverlay/Dismiss') })));
          this.jokerEnabled = m.prop(settings.jokerEnabled);
          this.jokerOverlay = m.prop(new Overlay(JokerOverlay(this.game(), 'eurojackpot-system-game-overlay', this.d, EurojackpotInfo.jokerData())));
          this.print = m.prop(false);
          this.property = property;
          this.property('currentRow', 1);
          this.subnavigation = m.prop(settings.quickLinks);

          // Functions:
          this.changeTab = function (tab) {
            var self = this;

            return function () {
              self.chosenTab(tab);
            };
          }.bind(this);

          this.purchase = function () {
            var game = this.game();

            if (game.isGameValid()) {
              var deferred = m.deferred();
              var self = this;

              if (this.jokerEnabled()) {
                this.jokerOverlay().show(deferred.resolve);
              } else {
                deferred.resolve();
              }

              deferred.promise.then(function () {
                game.prepareConfirm().then(function (confirmUrl) {
                  if (game.numberOfDraws() === 0) {
                    game.sendToSubscription();
                  } else {
                    location.href = confirmUrl;
                  }
                }, function () {
                  self.errorOverlay().show();
                });
              });
            }
          }.bind(this);

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          // Tracking - Push productDetails event to dataLayer
          this.game().trackingProductDetails();

        },

        view: function (controller) {
          if (settings.useNewNumbersPicker) {
            return (<NumberGamesSystem
              rootSettings={settings}
              rootProperty={property}
              gameInstance={controller.game}
              gameModel={EurojackpotGame}
              gameName={'eurojackpot'}
            />);
          }

          return m('div', [
            PrintWrapper(controller, 'eurojackpot', controller.chosenTab()),
            m('div', { class: 'eurojackpot-system-game' }, [
              !settings.subscriptionsMode ? ShakeOverlay(controller) : null,
              !settings.subscriptionsMode ? Subnavigation(controller) : null,
              OptionSelector(controller),
              StatisticsComponentForGameClient(controller, 'Eurojackpot'),
              m('div', { class: 'eurojackpot-section eurojackpot-system-game-section purchase-bar-container' }, [
                m('div', { class: 'eurojackpot-content-wrapper' }, [
                  m('div', { class: 'eurojackpot-game-tabs-wrapper' }, [
                    m('div', { class: 'tabs-navigation' }, [
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'game' ? ' active' : ''), onclick: controller.changeTab('game') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#game-menu-icon' })
                        ])
                      ]),
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'info' ? ' active' : ''), onclick: controller.changeTab('info') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#info-icon' })
                        ])
                      ]),
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'key' ? ' active' : ''), onclick: controller.changeTab('key') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#key-icon' })
                        ])
                      ]),
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'profit-guarantee' ? ' active' : ''), onclick: controller.changeTab('profit-guarantee') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#stamp-icon' })
                        ])
                      ])
                    ]),
                    m('div', { class: 'tabs-container' }, [

                      // Game tab:
                      controller.chosenTab() === 'game' ? m('div', { class: 'tab-container' }, [
                        m('div', { class: 'game-container' }, [
                          Row(controller)
                        ]),
                        !settings.subscriptionsMode ? NotificationBox('ejp_system', settings.notifications) : null
                      ]) : null,

                      // Info tab:
                      controller.chosenTab() === 'info' ? m('div', { class: 'tab-container' }, [
                        InfoTab(controller)
                      ]) : null,

                      // Key tab:
                      controller.chosenTab() === 'key' ? m('div', { class: 'tab-container' }, [
                        KeyTab(controller)
                      ]) : null,

                      // profit guarantee tab:
                      controller.chosenTab() === 'profit-guarantee' ? m('div', { class: 'tab-container' }, [
                        ProfitGuaranteeTab(controller)
                      ]) : null

                    ])
                  ]),
                  !settings.subscriptionsMode && !controller.property('isMultiClient') ? PurchaseBar(controller) : null
                ])
              ])
            ])
          ]);
        }
      };

      return root;
    };

    // Public functions:
    return GameClient;

  });
